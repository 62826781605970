jQuery(document).ready(function($) {
    // Code here will be executed on document ready. Use $ as normal.
	
	$('.hp-slider').slick ({
		arrows:false,
		fade:true,
		autoplay: true,
		pauseOnHover: false,
		dots: false,
	});
	
	$('.hp-latest-news').slick({
		infinite: true,
		slidesToShow: 3,
		arrows: false,
		autoplay: true,
		responsive: [
            {
			 breakpoint: 1024,
       settings: {
        slidesToShow: 2,arrows: true,
        slidesToScroll:2}
		
      },
        {
		breakpoint: 767,
       settings: {
        slidesToShow: 1,arrows: true,
        slidesToScroll: 1
      }
            }
        ]
	});

	$('.same-height').matchHeight({
		property: 'min-height',
	});
AOS.init();
}); //END DOCUMENT.READY



jQuery(window).resize(function($) {
	



}); //END WINDOW RESIZE

